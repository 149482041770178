<template>
    <div class="pt-3 pt-lg-0">
        <inner-page-card heading="Refund Template" class="d-sm-none-lg-block">
            <div class="fl-eqh-r w-100">
                <btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                    || currentUser.is_bank_admin || currentUser.permission.can_upload_refund"
                     color="secondary" icon="fa fa-plus" size="sm"
                     @click="$refs.addModal.show()" text="Add"/>
            </div>
            <custom-vue-table :show-search-box="false" class="" :fields="fields" :url="listUrl" :per-page="10"
                              ref="table">
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <delete-btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                    || currentUser.is_bank_admin || currentUser.permission.can_upload_refund"
                                    @click="setDelete(rowData)"/>
                        <edit-btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                    || currentUser.is_bank_admin || currentUser.permission.can_upload_refund"
                                  color="secondary" @click="setEdit(rowData)"/>
                    </div>
                </template>
                <template #is_enabled="{rowData}">
                    <div v-if="rowData.is_enabled">Enabled</div>
                    <div v-else>Disabled</div>
                </template>
            </custom-vue-table>

            <modal no-close-on-backdrop title="Add Refund Template" ref="addModal" width="40r" header-color="secondary">
                <b-form :save-url="addUrl" @success="formSuccess('Added')" v-slot="{model, loading}"
                        :initial-data="model1"
                        :save-params="{doc_type:'refund'}">
                    <validated-input
                        label="Name*" name="Name" v-model="model.name"
                        :rules="{required: true}" :disabled="loading"/>
                    <validated-file-input name="Template" label="Template" class="c-file-input"
                                          v-model="model.file" :disabled="loading"/>
                    <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                        :rules="{required: false}" :disabled="loading"/>
                    <btn color="secondary" text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                         :loading="loading"/>
                </b-form>
            </modal>

            <modal no-close-on-backdrop title="Edit Refund Template" ref="editModal" width="40r"
                   header-color="secondary">
                <b-form :save-url="addUrl" @success="formSuccess('Updated')" v-slot="{model, loading}"
                        :initial-data="editingItem">
                    <validated-input label="Name*" name="Name" v-model="model.name"
                                     :rules="{required: true}" :disabled="loading"/>
                    <validated-file-input name="Template" label="Template" class="c-file-input"
                                          v-model="model.file" :disabled="loading">
                        <template #append>
                            <div class="btn-group">
                                <btn v-if="editingItem.file"
                                     @click="viewFile(editingItem.file)"
                                     type="button" class="fa fa-eye" size="xs"/>
                            </div>
                        </template>
                    </validated-file-input>
                    <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                        :rules="{required: false}" :disabled="loading"/>
                    <btn color="secondary" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                         :loading="loading"/>
                </b-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the template <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                    ?</p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </inner-page-card>
        <refund-or-emd-template-mobile-view></refund-or-emd-template-mobile-view>
    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import { mapGetters } from 'vuex';
import RefundOrEmdTemplateMobileView from './RefundOrEmdTemplateMobileView';

export default {
    name       : 'RefundTemplate',
    components : { RefundOrEmdTemplateMobileView },
    data () {
        return {
            model1       : {},
            listUrl      : urls.masterData.RefundTemplate.list,
            addUrl       : urls.masterData.RefundTemplate.addEdit,
            deleteUrl    : urls.masterData.RefundTemplate.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Template Name'
                },
                {
                    name      : 'template_type',
                    sortField : 'template_type',
                    title     : 'Template Type'
                },
                {
                    name      : '__slot:is_enabled',
                    sortField : 'is_enabled',
                    title     : 'Enabled/Disabled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ],
            rules : {
                country : {
                    required : true
                }
            }
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    mounted () {
        this.setValues();
    },
    methods : {
        setValues (file) {
            if (this.currentUser.is_superuser || this.currentUser.is_ventura_admin) {
                this.model1.template_type = 'default';
            } else {
                this.model1.bank = this.currentUser.bank.id;
                this.model1.template_type = 'bank';
            }
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess (message) {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            this.$emit('refresh');
            this.$notify('Successfully ' + message + ' Refund Template', 'Success',
                {
                    type : 'success'
                }
            );
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.editingItem.doc_type = 'refund';

            if (this.currentUser.is_superuser || this.currentUser.is_ventura_admin) {
                this.editingItem.template_type = 'default';
            } else {
                this.editingItem.bank = this.currentUser.bank.id;
                this.editingItem.template_type = 'bank';
            }
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Successfully Deleted Refund Template', 'Success',
                {
                    type : 'success'
                }
            );
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped lang="scss">
.filter-grid {
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) 4fr;
    grid-template-rows: 1fr;
}

.filter-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    @media(min-width: 992px) {
        //grid-template-columns: 1fr 3fr repeat(3, 2fr) 3fr 3fr;
        grid-template-columns: 1fr repeat(3, 2fr) 4fr;
        grid-template-rows: 1fr;
    }
}
</style>
