<template>
    <div class="d-lg-none">
        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>
        <div v-else>
            <div class="fl-te-c">
                <h4 class="text-center">Refund Template</h4>
                <btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                    || currentUser.is_bank_admin || currentUser.permission.can_upload_refund"
                     color="secondary" icon="fa fa-plus" size="sm"
                     @click="$refs.addModal.show()" text="Add"/>
            </div>
            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">

                <div class="gr-2-cols">

                    <div class="fl-gr-1 b-1">
                        <!--                        <div class="fl-x fl-j-c fl-a-c">-->
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Id</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1">
                        <!--                        <div class="fl-x fl-j-s fl-a-c">-->
                        <div class="text-center">
                            <div class="pos-r py-2 fl-j-c">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Template Name</span>
                            </div>
                        </div>
                    </div>

                    <!--                    <div class="fl-gr-1 b-1">-->
                    <!--                        &lt;!&ndash;                        <div class="fl-x fl-j-s fl-a-c">&ndash;&gt;-->
                    <!--                        <div class="text-center">-->
                    <!--                            <div class="pos-r py-2 fl-j-c">-->
                    <!--                                &lt;!&ndash;                                <div class="line-right-1"></div>&ndash;&gt;-->
                    <!--                                <span class="font-inter-medium">Template Type</span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-2-cols font-inter-medium c-line-bottom">

                        <div class="br-1 py-2">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r fl-j-c">
                                    <div class=""></div>
                                    {{ i.id }}
                                </div>
                            </div>
                        </div>

                        <div class="br-1 fl-eqh-c py-2">
                            <div class="pos-r fl-j-c">
                                <div class="br-1 capitalize-string"></div>
                                {{ i.name }}
                            </div>
                        </div>

                        <!--                        <div class="pl-2 py-2">-->
                        <!--                            <div class="pos-r fl-j-c">-->
                        <!--                                <div class="line-right-1"></div>-->
                        <!--                                {{ i.template_type }}-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                    </div>

                    <div class="row fs--1 ">
                        <span class="w-100 col-6 b-1">
                            <div class="p-2">
                               <span class="font-inter-medium">Template Type : </span>
                                <span
                                    class="ml-2">{{ i.template_type }}</span>
                            </div>
                        </span>

                        <span class="b-1 w-100 col-6">
                            <div class="p-2">
                               <span class="font-inter-medium">Enabled/Disabled : </span> <span class="ml-2">{{ i.is_enabled }}</span>
                            </div>
                        </span>

                        <!--                        <span class="b-1 w-100 col-12">-->
                        <!--                            <div class="p-2">-->
                        <!--&lt;!&ndash;                               <span class="font-inter-medium">Mobile : </span> <span class="ml-2">{{ i.mobile }}</span>&ndash;&gt;-->
                        <!--                            </div>-->
                        <!--                        </span>-->
                        <span class="col-12">
                            <div class="p-1 text-center py-3 b-1">
<!--                                <btn class="ml-3" text="View Users" color="secondary"-->
                                <!--                                     @click="$router.push({path: '/bank-admin/' + i.id + '/users/'})"></btn>-->
                                <!--                                <div class="btn-group">-->
                                <!--                                    <btn size="sm" v-if="i.is_cancelled"-->
                                <!--                                         text="Cancel Reason" color="secondary" @click="showCancelReason(i)"/>-->

                                <!--                                    <btn size="sm" v-if="i.refund_status !== 'Completed'"-->
                                <!--                                         text="Update" color="secondary" @click="setUpdate(i)"/>-->
                                <!--                                    <btn size="sm" icon="fa fa-eye" color="secondary"-->
                                <!--                                         @click="$router.push({path: '/refund/' + $route.params.id + '/participants/' + i.id + '/details/'})"/>-->
                                <!--                                </div>-->
                                <div class="btn-group-tight">
                                    <delete-btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                    || currentUser.is_bank_admin || currentUser.permission.can_upload_refund"
                                                @click="setDelete(i)"/>
                                    <edit-btn v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                    || currentUser.is_bank_admin || currentUser.permission.can_upload_refund"
                                              color="secondary" @click="setEdit(i)"/>
                                </div>
                            </div>
                        </span>
                    </div>

                </div>
            </div>

            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>
        </div>
        <!--        Delete Modal-->
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the template <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                ?</p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
        <!--        Edit Modal-->
        <modal no-close-on-backdrop title="Edit Refund Template" ref="editModal" width="40r"
               header-color="secondary">
            <b-form :save-url="addUrl" @success="formSuccess('Updated')" v-slot="{model, loading}"
                    :initial-data="editingItem">
                <validated-input label="Name*" name="Name" v-model="model.name"
                                 :rules="{required: true}" :disabled="loading"/>
                <validated-file-input name="Template" label="Template" class="c-file-input"
                                      v-model="model.file" :disabled="loading">
                    <template #append>
                        <div class="btn-group">
                            <btn v-if="editingItem.file"
                                 @click="viewFile(editingItem.file)"
                                 type="button" class="fa fa-eye" size="xs"/>
                        </div>
                    </template>
                </validated-file-input>
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
                <btn color="secondary" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                     :loading="loading"/>
            </b-form>
        </modal>
        <!--        Add Modal-->
        <modal no-close-on-backdrop title="Add Refund Template" ref="addModal" width="40r" header-color="secondary">
            <b-form :save-url="addUrl" @success="formSuccess('Added')" v-slot="{model, loading}"
                    :initial-data="model1"
                    :save-params="{doc_type:'refund'}">
                <validated-input
                    label="Name*" name="Name" v-model="model.name"
                    :rules="{required: true}" :disabled="loading"/>
                <validated-file-input name="Template" label="Template" class="c-file-input"
                                      v-model="model.file" :disabled="loading"/>
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
                <btn color="secondary" text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                     :loading="loading"/>
            </b-form>
        </modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';

export default {
    name : 'RefundOrEmdTemplateMobileView',
    data () {
        return {
            prev         : '',
            next         : '',
            page         : '1',
            per_page     : '10',
            loading      : true,
            error        : false,
            details      : null,
            model1       : {},
            listUrl      : urls.masterData.RefundTemplate.list,
            addUrl       : urls.masterData.RefundTemplate.addEdit,
            deleteUrl    : urls.masterData.RefundTemplate.delete,
            editingItem  : null,
            deletingItem : null
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
        this.setValues();
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(that.listUrl, {
                params : {
                    // auction  : that.$route.params.id,
                    page     : that.page,
                    per_page : that.per_page
                }
            }).then(function (response) {
                const json = response.data;
                console.log('json', json);
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        setValues (file) {
            if (this.currentUser.is_superuser || this.currentUser.is_ventura_admin) {
                this.model1.template_type = 'default';
            } else {
                this.model1.bank = this.currentUser.bank.id;
                this.model1.template_type = 'bank';
            }
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Successfully Deleted Refund Template', 'Success',
                {
                    type : 'success'
                }
            );
            this.loadData();
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.editingItem.doc_type = 'refund';

            if (this.currentUser.is_superuser || this.currentUser.is_ventura_admin) {
                this.editingItem.template_type = 'default';
            } else {
                this.editingItem.bank = this.currentUser.bank.id;
                this.editingItem.template_type = 'bank';
            }
            this.$refs.editModal.show();
        },
        formSuccess (message) {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            this.$emit('refresh');
            this.$notify('Successfully ' + message + ' Refund Template', 'Success',
                {
                    type : 'success'
                }
            );
            this.loadData();
            this.editingItem = null;
        }
    }
};
</script>

<style scoped>

</style>
